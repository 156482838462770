@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: 'Fira Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer {
  margin-top: 1rem;
  padding: 1rem;
  bottom: 0;
  left: 0;
  width: 100%;
} */


@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');


body {
  background-color: #F5F7F8;
  color: #1E77E2;
  font-family: "Karla", sans-serif;
  margin: 0;
  padding: 0;
  /* Ensure there's no padding */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.coming-soon {
  text-align: center;
  font-size: 24px;
  width: 100%;
  /* Ensures the container takes the full width available */
  box-sizing: border-box;
  /* Includes padding and border in the width */
}

.coming-soon img {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  object-fit: cover;
  ;
  display: block;
  /* Makes the image a block element */
  margin: 0 auto;
  /* Auto margin for horizontal centering */
  padding: 2rem
}